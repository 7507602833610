// src/pages/Contratos.jsx
/*import React, { useState, useEffect, useContext } from 'react';
import Tabela from '../components/Tabela';
import ContratoModal from '../components/ContratoModal';
import ToastContext from '../context/ToastContext';
import contratoService from '../services/contratoService';

import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

const Contratos = () => {
  const { addToast } = useContext(ToastContext);
  const [contratos, setContratos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [contratoSelecionado, setContratoSelecionado] = useState(null);
  const [modoEdicao, setModoEdicao] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchContratos = async () => {
      try {
        const data = await contratoService.getAll();
        setContratos(data);
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      }
    };
    fetchContratos();
  }, []);

  const handleNovoContrato = () => {
    setContratoSelecionado(null);
    setModoEdicao(false);
    setShowModal(true);
  };

  const handleEditContrato = (contrato) => {
    setContratoSelecionado(contrato);
    setModoEdicao(true);
    setShowModal(true);
  };

  const handleDeleteContrato = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir este contrato?')) {
      try {
        await contratoService.delete(id);
        setContratos(contratos.filter((c) => c.id !== id));
        addToast({
          title: 'Contrato excluído!',
          description: 'O contrato foi excluído com sucesso.',
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      }
    }
  };

  return (
    <div className="d-flex flex-column p-2 h-100 bg-dark">
      <div className="container d-flex flex-column h-100 mt-2 mb-4 bg-light p-4 rounded shadow ">
        <h1>Contratos</h1>
        <Button variant="primary" onClick={handleNovoContrato} className="mb-3">
          <FaPlus /> Novo Contrato
        </Button>
        <div className="d-flex">
          <Tabela
            rawData={contratos}
            onEdit={handleEditContrato}
            onDelete={handleDeleteContrato}
          />
        </div>
        <ContratoModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          contrato={contratoSelecionado}
          modoEdicao={modoEdicao}
          isLoading={isLoading} // Passar isLoading como prop
          setIsLoading={setIsLoading} // Passar setIsLoading como prop
        />
      </div>
    </div>
  );
};

export default Contratos;
*/
import React, { useState, useEffect, useContext } from 'react';
import CrudTabela from '../components/CrudTabela';
import contratoService from '../services/contratoService';
import ToastContext from '../context/ToastContext';

const Contratos = () => {
  const [dados, setDados] = useState([]);
  const [optionsLists, setOptionsLists] = useState({});
  const { addToast } = useContext(ToastContext);

  const [dadosLoading, setDadosLoading] = useState(true);

  useEffect(() => {
    const fetchDados = async () => {
      try {
        setDadosLoading(true);
        const secondaryCrudServices = [];
        const mainCrudService = contratoService;

        const data = await mainCrudService.getAll();
        const allOptions = await Promise.all(
          secondaryCrudServices.map(async ([nome, func]) => {
            const response = await func.getAll();
            return [
              nome,
              response.map((valor) => {
                let mergedvalues = '';
                for (let ks in valor) {
                  if (
                    ks.slice(0, 7) === 'created' ||
                    ks.slice(0, 7) === 'updated'
                  )
                    continue;
                  mergedvalues = `${mergedvalues}  |  ${ks} ${valor[ks]}`;
                }
                return {
                  value: valor.id,
                  label: mergedvalues, // You might want to replace with a more descriptive label
                };
              }),
            ];
          }),
        );
        const mergedOptions = allOptions.flat();

        let optionsDict = {};
        for (let i = 0; i < mergedOptions.length; i += 2) {
          optionsDict[mergedOptions[i]] = mergedOptions[i + 1];
        }

        setOptionsLists(optionsDict);
        setDados(data);
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      } finally {
        setDadosLoading(false);
      }
    };
    fetchDados();
  }, []);

  const handleSetDados = (dado) => setDados(dado);
  console.log(handleSetDados);
  return (
    <CrudTabela
      dados={dados}
      onSetDados={setDados}
      optionsLists={optionsLists}
      mainCrudService={contratoService}
      isLoading={dadosLoading}
      setIsLoading={setDadosLoading}
      pluralDado="Contratos"
      nameDado="contrato"
      className="tabelaSmall"
      formModel={[
        ['contrato', 0, 'Número do contrato'],
        ['empresa', 'str', 'Nome da Empresa'],
        ['inicio', 'dd/MM/aaaa', 'Data de início do contrato'],
        ['fim', 'dd/MM/aaaa', 'Data de fim do contrato'],
        ['projeto', 'str', 'Nome do Projeto (oficial)'],
        ['nomesimplificado', 'str', 'Apelido do projeto'],
      ]}
    />
  );
};

export default Contratos;
