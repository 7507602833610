import PropTypes from 'prop-types';
import './Cell.css';

const Cell = ({
  onClick,
  children,
  className,
  isActive = false,
  isSelected,
  isSameMonth,
  hasConvocacao,
}) => {
  if (isSelected) className += ' cell-ativa ';
  if (hasConvocacao) className += ' cell-items ';
  if (isSameMonth) className += ' cell-vivid ';

  return (
    <div
      onClick={!isActive ? onClick : undefined}
      className={`calendar-cell ${className}`}
    >
      {children}
      {hasConvocacao && <div> ♦ </div>}
    </div>
  );
};

Cell.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.string,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSameMonth: PropTypes.bool,
  hasConvocacao: PropTypes.bool,
};

export default Cell;
