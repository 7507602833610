// src/components/DynamicModal.jsx
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import ToastContext from '../context/ToastContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputTabela from './InputTabela.jsx';
import CrossOption from './FormComponents/CrossOption.jsx';
import FixedOption from './FormComponents/FixedOption.jsx';
import CreatableOption from './FormComponents/CreatableOption.jsx';

import { toZonedTime } from 'date-fns-tz';

const DynamicModal = ({
  show,
  onSetDado,
  onClose,
  dadoSelecionado,
  modoEdicao,
  isLoading,
  formModel,
  optionsLists,
  nameDado,
  crudService,
  alterNames,
  keyp,
  changeModel,
}) => {
  const { addToast } = useContext(ToastContext);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(false);

  useEffect(() => {
    if (dadoSelecionado) setFormData(dadoSelecionado);
    const defaultdados = {};
    formModel.map(([name]) => (defaultdados[name] = null));
    if (!dadoSelecionado) setFormData(JSON.parse(JSON.stringify(defaultdados)));
  }, [dadoSelecionado, formModel]);

  const handleChange = (event) => {
    if (event?.preventDefault) event.preventDefault();
    const targetName = event.target.name;
    if (formData?.[targetName] === event.target.value) return;
    return setFormData({
      ...formData,
      [targetName]: event.target.value || null,
    });
  };

  const handleForeignChange = (event) => {
    const targetName = event?.name;
    if (formData?.[targetName] === event?.value) return;
    return setFormData({ ...formData, [targetName]: event.value || null });
  };

  const handleForeignChangeModel = (event) => {
    return changeModel.func(event?.value);
  };

  const handleDateChange = (field, date) => {
    if (formData && formData[field] === date) return;
    setFormData({
      ...formData,
      [field]: date ? new Date(date) : null, // Formata a data para o formato ISO
    });
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const form = event.currentTarget;

      // Check if all required fields are filled
      const isFormValid = formModel.every(([name, , , , required]) => {
        const notRequired = required === false;
        const hasInfo = !!formData[name];
        return notRequired || hasInfo; // Check for non-required or filled
      });

      if (!isFormValid || form.checkValidity() === false) {
        event.stopPropagation();

        setValidated(true); // Trigger Bootstrap's validation styles
        return;
      }

      if (modoEdicao) {
        const response = await crudService.update(dadoSelecionado.id, formData);
        addToast({
          title: `${nameDado} atualizado!`,
          description: `O ${nameDado} foi atualizado com sucesso.`,
          type: 'success',
        });
        onSetDado((dados) =>
          dados.map((vl) => (vl.id === dadoSelecionado.id ? response : vl)),
        );
      } else {
        const response = await crudService.create(formData);
        addToast({
          title: `${nameDado} criado!`,
          description: `O ${nameDado} foi criado com sucesso.`,
          type: 'success',
        });
        onSetDado((dados) => [...dados, response]);
      }
      onClose();
    } catch (error) {
      addToast({
        title: 'Erro',
        description:
          error.message || `Ocorreu um erro ao salvar o ${nameDado}.`,
        type: 'danger',
      });
    }
  };

  return (
    <Modal key={keyp + 'c12'} show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {modoEdicao ? `Editar ${nameDado}` : `Cadastrar ${nameDado}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {changeModel && (
          <FixedOption
            keyp={'changeModel'}
            value={changeModel.options[0]}
            name={'changeModel'}
            label={'Tipo'}
            onChange={handleForeignChangeModel}
            optionsList={changeModel.options}
          />
        )}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {formModel &&
            formModel.map((arrvalues) => {
              const [name, sample, , options, columns, linkAdd] = arrvalues;
              const value = (formData && formData[name]) || null;
              const newkey = keyp + 'inp' + name;

              const vlshow = ' ' || ( //ESTA LINHA SERVE PARA DEBUGAR O FORMULÁRIO, TROQUE O ' ' POR '' PARA UTILIZAR.
                <span className="bg-dark text-white">
                  {(formData && formData[name]) || null}
                </span>
              );

              if (columns) {
                return (
                  <>
                    {vlshow}
                    <InputTabela
                      key={newkey}
                      keyp={newkey}
                      name={name}
                      columns={columns}
                      label={alterNames[name]}
                      onChange={handleChange}
                      value={value}
                      required={true}
                    />
                  </>
                );
              }
              if (options && options.length > 0 && sample === 'crs') {
                return (
                  <>
                    {vlshow}
                    <CreatableOption
                      key={newkey}
                      keyp={newkey}
                      label={alterNames[name] || name}
                      name={name}
                      value={value}
                      onChange={handleForeignChange}
                      required={true}
                      optionsList={options}
                    />
                  </>
                );
              }
              if (options && options.length > 0) {
                return (
                  <>
                    {vlshow}
                    <FixedOption
                      key={newkey}
                      keyp={newkey}
                      value={value}
                      name={name}
                      label={alterNames[name] || name}
                      onChange={handleForeignChange}
                      required={true}
                      optionsList={options}
                    />
                  </>
                );
              }
              if (sample === 'str')
                return (
                  <>
                    {vlshow}
                    <Form.Group key={newkey} className="mb-2" controlId={name}>
                      <Form.Label>{alterNames[name] || name}</Form.Label>
                      <Form.Control
                        key={newkey + 'c'}
                        type="text"
                        name={name}
                        placeholder={alterNames[name] || name}
                        value={value || ''}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, informe {alterNames[name] || name}.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                );
              if (sample === 'dd/MM/aaaa') {
                try {
                  console.log(
                    formData && formData[name] && formData[name]?.toISOString(),
                  );
                  return (
                    <Form.Group className="mb-2" controlId={name} key={newkey}>
                      <Form.Label className="w-100">
                        {alterNames[name] || name}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <InputGroup.Text>Dia</InputGroup.Text>
                        <DatePicker
                          key={newkey + 'c'}
                          selected={
                            formData && formData[name]
                              ? toZonedTime(formData[name])
                              : null
                          }
                          onChange={(date) => handleDateChange(name, date)}
                          dateFormat="yyyy-MM-dd"
                          className="form-control p-1"
                          required
                          showTimeZone={true} // Exibe o fuso horário
                          timeZone="UTC" // Define o fuso horário como UTC
                        />
                        <Form.Control.Feedback type="invalid">
                          Por favor, informe a data de realização das inspeções.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  );
                } catch {
                  (e) => console.log(e);
                }
              }
              if (
                typeof sample === 'number' &&
                name.length > 2 &&
                name.slice(-3) !== '_id'
              )
                return (
                  <>
                    {vlshow}
                    <Form.Group key={newkey} className="mb-2" controlId={name}>
                      <Form.Label>{alterNames[name] || name}</Form.Label>
                      <Form.Control
                        key={newkey + 'c'}
                        type="number"
                        name={name}
                        placeholder={`${alterNames[name] || name}`}
                        value={value}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, informe o número {alterNames[name] || name}.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                );
              if (typeof sample === 'number' && name.slice(-3) === '_id') {
                return (
                  <>
                    {vlshow}
                    <CrossOption
                      linkAdd={linkAdd}
                      key={newkey}
                      keyp={newkey}
                      name={name}
                      label={alterNames[name] || name}
                      value={value}
                      onChange={handleForeignChange}
                      required={true}
                      optionsList={optionsLists[name]}
                    />
                  </>
                );
              }
            })}
          <Button
            className="mt-3"
            variant="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading
              ? 'Salvando...'
              : modoEdicao
              ? 'Salvar Alterações'
              : `Criar ${nameDado}`}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

DynamicModal.propTypes = {
  keyp: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onSetDado: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  dadoSelecionado: PropTypes.object,
  formModel: PropTypes.array.isRequired,
  optionsLists: PropTypes.object,
  modoEdicao: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  nameDado: PropTypes.string.isRequired,
  crudService: PropTypes.object.isRequired,
  alterNames: PropTypes.object,
  changeModel: PropTypes.object,
};

export default DynamicModal;
