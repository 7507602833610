import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Cell from './Calendar/Cell';
import './CalendarioFiltro.css';
import {
  format,
  eachDayOfInterval,
  isSameMonth,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameDay,
  //addDays,
} from 'date-fns';

import { fromZonedTime } from 'date-fns-tz';
const CalendarioFiltro = ({
  onDataSelecionada,
  dataSelecionada,
  diasComConvocacoes,
  isLoading,
  className,
}) => {
  const dataAtual = dataSelecionada;

  const filtroMesInicio = startOfWeek(
    startOfMonth(fromZonedTime(dataSelecionada, 'UTC')),
    {
      weekStartsOn: 0,
    },
  );
  const filtroMesFim = endOfWeek(
    endOfMonth(fromZonedTime(dataSelecionada, 'UTC')),
    {
      weekStartsOn: 0,
    },
  );

  const diasDoMes = eachDayOfInterval({
    start: filtroMesInicio,
    end: filtroMesFim,
  });

  const semanasDoMes = [];
  let diasDaSemana = [];

  diasDoMes.forEach((dia) => {
    diasDaSemana.push(dia);
    if (diasDaSemana.length === 7) {
      semanasDoMes.push(diasDaSemana);
      diasDaSemana = [];
    }
  });

  const handleClickDia = (dia) => {
    onDataSelecionada(dia);
  };

  const handleProximoMes = () => {
    onDataSelecionada(addMonths(fromZonedTime(dataAtual, 'UTC'), 1));
  };

  const handleMesAnterior = () => {
    onDataSelecionada(subMonths(fromZonedTime(dataAtual, 'UTC'), 1));
  };

  return (
    <div className={`calendar ${className}`}>
      <div className="calendar-header">
        <div className="calendar-header-buttons">
          <Cell
            className="cell-header cell-header-button"
            onClick={handleMesAnterior}
          >
            {'<'}
          </Cell>

          <Cell className="cell-header">
            {format(fromZonedTime(dataAtual, 'UTC'), 'LLLL yyyy')}
          </Cell>
          <Cell
            className="cell-header cell-header-button"
            onClick={handleProximoMes}
          >
            {'>'}
          </Cell>
        </div>

        <div className="calendar-header-row">
          {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'].map((dia) => (
            <Cell key={dia} className="cell-header">
              {dia}
            </Cell>
          ))}
        </div>

        {isLoading ? ( // Exibe um spinner enquanto as opções estão carregando
          <div className="row">
            <div className="col h-100 w-100">
              <div className="d-flex justify-content-center bg-white align-items-center h-100 ">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              </div>
            </div>
          </div>
        ) : (
          semanasDoMes.map((semana) => (
            <div className="calendar-row" key={semana[0].getTime()}>
              {semana.map((dia) => {
                return (
                  <Cell
                    onClick={() => handleClickDia(fromZonedTime(dia, 'UTC'))}
                    key={dia.getTime()}
                    className="cell-value"
                    isSelected={isSameDay(
                      fromZonedTime(dia, 'UTC'),
                      fromZonedTime(dataAtual, 'UTC'),
                    )}
                    isSameMonth={isSameMonth(
                      dia,
                      fromZonedTime(dataAtual, 'UTC'),
                    )}
                    hasConvocacao={diasComConvocacoes.some((d) =>
                      isSameDay(
                        fromZonedTime(d, 'UTC'),
                        fromZonedTime(dia, 'UTC'),
                      ),
                    )}
                  >
                    {dia.getDate()}
                  </Cell>
                );
              })}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

CalendarioFiltro.propTypes = {
  onDataSelecionada: PropTypes.func.isRequired,
  dataSelecionada: PropTypes.instanceOf(Date).isRequired,
  diasComConvocacoes: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default CalendarioFiltro;
