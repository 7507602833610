import React, { useState, useEffect, useContext } from 'react';
import localService from '../services/localService';
import CrudConvocacao from '../components/CrudConvocacao';
import { Form, Spinner } from 'react-bootstrap';
import contratoService from '../services/contratoService';
import convocacaoService from '../services/convocacaoService';
import CalendarioFiltro from '../components/CalendarioFiltro';
import useMatchMedia from '../context/useWindowSize';
import ToastContext from '../context/ToastContext';

import Select from 'react-select';
import {
  startOfWeek,
  isSameMonth,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
} from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import FUNCS from '../utils';
const isDateTime = FUNCS.isDateTime;

const Convocacoes = () => {
  const isDesktopResolution = useMatchMedia('(min-width:992px)', true);
  const { addToast } = useContext(ToastContext);
  const [dados, setDados] = useState([]);
  const [dataSelecionada, setDataSelecionada] = useState(
    new Date(
      Date.UTC(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ),
    ),
  );
  const [optionsLists, setOptionsLists] = useState({});
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [dadosLoading, setDadosLoading] = useState(true);
  const [contratoSelecionado, setContratoSelecionado] = useState(null);
  const [recarregarBase, setRecarregarBase] = useState(true);
  const [recarregarOptions, setRecarregarOptions] = useState(true);

  const filtroSemanaInicio = dataSelecionada; //startOfWeek(dataSelecionada, { weekStartsOn: 0 });
  const filtroSemanaFim = dataSelecionada; //endOfWeek(dataSelecionada, { weekStartsOn: 0 });

  const diasComConvocacoes = dados?.map(
    (convocacao) => new Date(convocacao.data_inspecao),
  );

  const convocacoesFiltradas = dados.filter((convocacao) => {
    /*new Date(convocacao.data_inspecao) >= filtroSemanaInicio &&
      new Date(convocacao.data_inspecao) <= filtroSemanaFim,*/
    return isSameDay(
      fromZonedTime(new Date(convocacao.data_inspecao), 'UTC'),
      fromZonedTime(filtroSemanaInicio, 'UTC'),
    );
  });

  const diasDoMes = eachDayOfInterval({
    start: startOfMonth(fromZonedTime(dataSelecionada, 'UTC')),
    end: endOfMonth(fromZonedTime(dataSelecionada, 'UTC')),
  });

  const semanasDoMes = [];
  let diasDaSemana = [];

  diasDoMes.forEach((dia) => {
    diasDaSemana.push(dia);
    if (diasDaSemana.length === 7) {
      semanasDoMes.push(diasDaSemana);
      diasDaSemana = [];
    }
  });

  const mainCrudService = convocacaoService;

  useEffect(() => {
    const fetchDados = async () => {
      try {
        setDadosLoading(true);
        const filtroMesInicio = startOfWeek(
          startOfMonth(fromZonedTime(dataSelecionada, 'UTC')),
          {
            weekStartsOn: 0,
          },
        );
        const filtroMesFim = endOfWeek(
          endOfMonth(fromZonedTime(dataSelecionada, 'UTC')),
          {
            weekStartsOn: 0,
          },
        );
        if (recarregarBase) {
          const newdata = await mainCrudService.getAll(
            filtroMesInicio
              ? filtroMesInicio.toISOString().slice(0, 19).replace('T', ' ')
              : null,
            filtroMesFim
              ? filtroMesFim.toISOString().slice(0, 19).replace('T', ' ')
              : null,
            contratoSelecionado,
          );
          setDados(newdata);
          setRecarregarBase(false);
        }

        // Extrair as datas das convocações e atualizar o estado diasComConvocacoes
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      } finally {
        setDadosLoading(false);
      }
    };
    fetchDados();
  }, [recarregarBase]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const secondaryCrudServices = [];
        const newOptions = {};
        const params = {
          contrato_id: contratoSelecionado,
        };

        if (!contratoSelecionado) {
          secondaryCrudServices.push(['contrato_id', contratoService]);
        } else {
          newOptions['contrato_id'] = optionsLists['contrato_id'];
          secondaryCrudServices.push(['local_id', localService]);
          secondaryCrudServices.push(['pit_id', localService]);
        }
        setOptionsLoading(true);

        const options = await Promise.all(
          secondaryCrudServices.map(async ([nome, func]) => {
            const response = await func.getAll(params);
            return {
              nome,
              options: response.map((valor) => ({
                value: valor.id,
                name: nome,
                label: Object.entries(valor)
                  .filter(
                    ([key, val]) =>
                      !key.startsWith('created') &&
                      !key.startsWith('updated') &&
                      !isDateTime(val) &&
                      val,
                  )
                  .map(([key, val]) => `${key}: ${val}`)
                  .join(' | '),
              })),
            };
          }),
        );

        const addOptions = options.reduce(
          (acc, { nome, options }) => ({ ...acc, [nome]: options }),
          {},
        );

        for (let key in addOptions) {
          newOptions[key] = addOptions[key];
        }

        setOptionsLists(newOptions);
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      } finally {
        setOptionsLoading(false);
        setRecarregarOptions(false);
      }
    };
    if (recarregarOptions) {
      fetchOptions();
    }
  }, [recarregarOptions]);

  const handleDataSelecionada = (data) => {
    setDataSelecionada((oldData) => {
      if (!isSameMonth(oldData, data)) setRecarregarBase(true);
      if (!isSameMonth(oldData, data)) setDadosLoading(true);
      return data;
    });
  };

  const handleChangeContrato = (selectedOption) => {
    setContratoSelecionado(selectedOption ? selectedOption.value : null);
    setRecarregarBase(true);
    setRecarregarOptions(true);
  };
  //FILTRAR OPTION LISTS:
  const filteredLists = {};
  for (let key in optionsLists) {
    if (key !== 'contrato_id') {
      filteredLists[key] = optionsLists[key];
    }
    if (key === 'contrato_id') {
      filteredLists[key] =
        optionsLists.contrato_id?.filter(
          (vl) => vl.value === contratoSelecionado,
        ) || null;
    }
  }

  return (
    <div className={`container-xl align-items-start p-0 h-100 w-100 bg-dark`}>
      <div className="d-flex p-2 mt-1 mx-1 rounded shadow-sm justify-items-stretch bg-white">
        <Form.Group key="contrato_id" className="w-100" controlId="contrato_id">
          <Form.Label>Contratos</Form.Label>
          {optionsLoading ? ( // Exibe um spinner enquanto as opções estão carregando
            <div className="container-fluid justify-content-center bg-white align-items-center w-100">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Carregando...</span>
              </Spinner>
            </div>
          ) : (
            <Select
              name={'contrato_id'}
              value={optionsLists?.contrato_id?.find(
                (option) => option.value === contratoSelecionado,
              )}
              onChange={handleChangeContrato}
              options={optionsLists?.contrato_id}
              isClearable
            />
          )}
        </Form.Group>
      </div>
      <div
        className={`d-flex p-0 ${
          isDesktopResolution
            ? ' align-items-start justify-content-center '
            : 'flex-column align-items-center justify-content-start '
        } w-100 h-100`}
      >
        <CalendarioFiltro
          className="p-3 m-1 justify-self-center shadow-sm"
          isLoading={dadosLoading}
          onDataSelecionada={handleDataSelecionada}
          diasComConvocacoes={diasComConvocacoes}
          dataSelecionada={dataSelecionada}
        />

        <CrudConvocacao
          podeCadastrar={contratoSelecionado > 0}
          forceCard={true}
          className=""
          isLoading={dadosLoading}
          setIsLoading={setDadosLoading}
          onSetDados={setDados}
          dados={convocacoesFiltradas} // Filtra as convocações
          optionsLists={filteredLists}
          mainCrudService={convocacaoService}
          pluralDado={`Convocações - ${filtroSemanaInicio
            .toISOString()
            .slice(0, 10)} à ${filtroSemanaFim.toISOString().slice(0, 10)}`}
          nameDado="convocação"
          formModel={[
            ['contrato_id', 0, 'Contrato'],
            ['local_id', 0, 'Local', null, null, '/locais'],
            [
              'telefone_inspetorcontratada',
              'str',
              'Telefone do inspetor da empresa contratada',
            ],
            [
              'nome_inspetorcontratada',
              'str',
              'Nome do inspetor da empresa contratada',
            ],
            [
              'tipo_acompanhamento',
              'str',
              'Tipo de acompanhamento',
              [
                [
                  'HP',
                  'HP - Obrigatória a presença da VALE para comprovação da inspeção',
                ],
                [
                  'WP',
                  'WP - Opcional a presença da VALE, porém obrigatória a convocação',
                ],
              ],
            ],

            [
              'tipo_processo',
              'str',
              'Tipo de processo produtivo',
              [['Fabricação'], ['Montagem'], ['Obra Civil'], ['Instalação']],
            ],
            [
              'disciplina',
              'str',
              'Disciplina relacionada',
              [
                ['C', 'C - Civil'],
                ['E', 'E - Elétrica'],
                ['J', 'J - Automação Industrial'],
                ['S', 'S - Estruturas Metálicas'],
                ['M', 'M - Mecânica'],
                ['V', 'V - Topografia'],
                ['T', 'T - Tubulação'],
              ],
            ],
            [
              'processo_produtivo',
              'crs',
              'Processo produtivo',
              [
                ['Fabricação e montagem de estruturas metálicas'],
                ['Usinagem de peças e componentes'],
                ['Fabricação e montagem de tubulações'],
                ['Fabricação e montagem de vasos de pressão'],
                [
                  'Fabricação e montagem de equipamentos rotativos (bombas, motores, redutores)',
                ],
                ['Fabricação e montagem de sistemas de ventilação e exaustão'],
                [
                  'Fabricação e montagem de sistemas de ar condicionado e refrigeração',
                ],
                ['Fabricação e montagem de painéis elétricos'],
                ['Fabricação e instalação de transformadores'],
                ['Instalação de sistemas de iluminação'],
                ['Instalação de sistemas de proteção contra incêndio'],
                [
                  'Instalação de sistemas de proteção contra descargas atmosféricas (SPDA)',
                ],
                ['Instalação de sistemas de aterramento elétrico'],
                ['Instalação de sistemas de automação e controle'],
                ['Instalação de sistemas de segurança e monitoramento'],
                ['Instalação de sistemas de comunicação e dados'],
                ['Instalação de sistemas de proteção ambiental'],
                ['Instalação de sistemas de tratamento de efluentes'],
                ['Instalação de sistemas de tratamento de água'],
                ['Instalação de sistemas de tratamento de resíduos'],
                [
                  'Obras civis (fundações, estruturas de concreto armado, alvenaria, etc.)',
                ],
                [
                  'Instalações prediais (hidráulicas, sanitárias, elétricas, etc.)',
                ],
              ],
            ],
            [
              'etapa_processo',
              'str',
              'Etapa do processo',
              [['Antes'], ['Durante'], ['Após']],
            ],
            [
              'tipo_inspecao',
              'crs',
              'Tipo de inspeção',
              [
                ['Inspeção visual de soldas'],
                ['Inspeção de posicionamento de estruturas'],
                ['Inspeção dimensional de estruturas metálicas'],
                ['Teste de carga em estruturas metálicas'],
                ['Inspeção de juntas soldadas por líquido penetrante (LP)'],
                ['Inspeção de juntas soldadas por partículas magnéticas (PM)'],
                ['Inspeção de juntas soldadas por ultrassom (UT)'],
                ['Inspeção de juntas soldadas por radiografia (RT)'],
                ['Inspeção de espessura de chapa'],
                ['Inspeção de revestimentos anticorrosivos'],
                ['Inspeção de pintura industrial'],
                ['Medição de rugosidade superficial'],
                ['Teste de aderência de pintura'],
                ['Inspeção dimensional de peças usinadas'],
                ['Inspeção de acabamento superficial de peças usinadas'],
                ['Inspeção de dureza de materiais'],
                ['Inspeção de tratamento térmico'],
                ['Inspeção de microestrutura de materiais'],
                ['Ensaio de tração em materiais'],
                ['Ensaio de dobramento em materiais'],
                ['Ensaio de dureza em soldas'],
                ['Ensaio de impacto Charpy em soldas'],
                ['Ensaio de macrografia em soldas'],
                ['Inspeção de soldas em tubulações'],
                ['Teste hidrostático em tubulações'],
                ['Teste pneumático em tubulações'],
                ['Inspeção de válvulas em tubulações'],
                ['Inspeção de flanges em tubulações'],
                ['Inspeção de juntas de expansão em tubulações'],
                ['Inspeção de suportes de tubulações'],
                ['Inspeção de alinhamento e nivelamento de equipamentos'],
                ['Inspeção de folgas e tolerâncias de montagem'],
                ['Inspeção de aperto de parafusos e porcas'],
                ['Inspeção de torque em conexões flangeadas'],
                ['Inspeção de lubrificação de mancais e rolamentos'],
                ['Inspeção de vedação de flanges e conexões'],
                ['Inspeção de bombas e seus componentes'],
                ['Teste de desempenho de bombas hidráulicas'],
                ['Teste de corrente de partida em motores elétricos'],
                ['Teste de vibração em motores elétricos'],
                ['Teste de ruído em redutores e motorredutores'],
                ['Teste de desempenho de ventiladores e exaustores'],
                ['Medição de vazão de ar em ventiladores e exaustores'],
                ['Medição de nível de ruído em ventiladores e exaustores'],
                ['Teste de eficiência de filtragem'],
                ['Teste de estanqueidade em trocadores de calor'],
                ['Inspeção de tubos em trocadores de calor'],
                ['Teste de pressão em reatores químicos'],
                ['Inspeção de agitadores em reatores químicos'],
                ['Inspeção de revestimentos refratários em fornos'],
                ['Teste de temperatura em fornos'],
                ['Teste de desempenho de britadores'],
                ['Inspeção de desgaste em britadores'],
                ['Teste de desempenho de moinhos'],
                ['Inspeção de revestimentos em moinhos'],
                ['Teste de eficiência de peneiramento'],
                ['Inspeção de malhas em peneiras'],
                ['Teste de desempenho de células de flotação'],
                ['Inspeção de rotores e estatores em células de flotação'],
                ['Teste de desempenho de espessadores'],
                ['Inspeção de raspadores em espessadores'],
                ['Teste de desempenho de filtros'],
                ['Inspeção de telas filtrantes'],
                ['Teste de desempenho de secadores e evaporadores'],
                ['Inspeção de queimadores em secadores e evaporadores'],
                ['Ensaio de relação de transformação em transformadores'],
                ['Ensaio de rigidez dielétrica em transformadores'],
                ['Teste de aquecimento em painéis elétricos'],
                ['Teste de tensão de ruptura em cabos elétricos'],
                ['Teste de fluxo luminoso em luminárias e projetores'],
                ['Teste de distribuição de luz em luminárias e projetores'],
                ['Teste de eficiência de equipamentos de proteção ambiental'],
                ['Teste de estanqueidade das tubulações de incêndio'],
                ['Medição da pressão do sistema de ar comprimido'],
                ['Análise da qualidade do ar comprimido'],
                ['Medição da pressão do sistema hidráulico'],
                ['Análise do fluido hidráulico'],
                ['Verificação da velocidade da correia transportadora'],
                [
                  'Verificação da temperatura dos mancais da correia transportadora',
                ],
                ['Medição da resistência de aterramento'],
                ['Verificação da continuidade do sistema de aterramento'],
                ['Inspeção de hastes de aterramento'],
                ['Inspeção de conexões de aterramento'],
                ['Teste de disjuntores'],
                ['Teste de relés de proteção'],
                ['Teste de dispositivos de proteção contra surtos (DPS)'],
                ['Teste de sistemas de detecção de arco elétrico'],
                ['Teste de sistemas de proteção contra falta de fase'],
                ['Teste de sistemas de proteção contra sobrecorrente'],
                ['Teste de sistemas de proteção contra sobretensão'],
                ['Medição da iluminância'],
                ['Verificação da uniformidade da iluminação'],
                ['Verificação do índice de reprodução de cor (IRC)'],
                ['Verificação da temperatura de cor'],
                ['Verificação do ofuscamento (UGR)'],
                [
                  'Verificação do funcionamento de sistemas de controle de iluminação',
                ],
                ['Verificação de certificados de materiais'],
                ['Verificação de projetos e desenhos técnicos'],
                ['Verificação de procedimentos de teste e inspeção'],
                ['Verificação de registros de qualidade'],
                ['Verificação de conformidade com normas e regulamentos'],
                ['Verificação de planos de manutenção'],
                ['Inspeção de fundações'],
                ['Inspeção de estruturas de concreto armado'],
                ['Inspeção de alvenaria e vedações'],
                ['Inspeção de coberturas e telhados'],
                ['Inspeção de revestimentos e acabamentos'],
                ['Inspeção de impermeabilização'],
              ],
            ],
            ['criterio_aceitacao', 'str', 'Critério de aceitação'],
            [
              'tag_identificacao_convocados',
              'str',
              'TAG/Identificação dos itens que serão inspecionados',
              null,
              ['TAG/Identificação', 'Tipo'],
            ],
            [
              'quantidade_inspecoes',
              0,
              'Quantidade de itens que serão inspecionados',
            ],
            ['data_inspecao', 'dd/MM/aaaa', 'Dia de realização das inspeções'],
            [
              'turno',
              'str',
              'Período em que irá ocorrer a inspeção',
              [['Madrugada'], ['Manhã'], ['Tarde'], ['Noite']],
            ],
            [
              'criticidade',
              'str',
              'Os itens que são objetos da inspeção possuem ou são integrantes de equipamentos/estruturas críticas?',
              [
                [
                  'Sim',
                  'Sim - Conforme definido no plano preliminar de inspeções',
                ],
                [
                  'Não',
                  'Não - Conforme definido no plano preliminar de inspeções',
                ],
              ],
            ],
            [
              'modelo_relatorio',
              'str',
              'Documentos de referência para a inspeção',
              null,
              ['Código do documento', 'Revisão'],
            ],
            /*['pit_id', 0, 'Item do PIT'],
            ['pititem_id', 0, 'PIT associado'],*/
          ]}
        />
      </div>
    </div>
  );
};

export default Convocacoes;
