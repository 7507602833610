import React from 'react';
import { Form, Spinner, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CreatableSelect from 'react-select/creatable';
const FixedOption = ({
  label,
  name,
  optionsList,
  isLoading,
  onChange,
  value = '',
  required,
  keyp,
}) => {
  const erros = [];
  const hasError = required && !value;
  let disabled = false;
  //Se não há opções, mostrar erro

  if (!optionsList) {
    optionsList = [null, 'Selecionar...', name];
    erros.push(
      'Não há opção para selecionar. Verifique se esse campo já foi cadastrado em outro formulário',
    );
    disabled = true;
  }
  optionsList = optionsList.map((arr) => ({
    value: arr[0],
    label: arr[1] || arr[0],
    name: name,
  }));

  //Se há apenas uma opção, selecionar ela mesma.

  /*const dflt = { value: value, label: value, name: name };

  //Se há nome, não há valor padrão no form e há valor definido pelo dflt, update form
  if (dflt?.name && !value && dflt?.value) {
    onChange(dflt);
  }*/

  if (!onChange) {
    onChange = () => ({});
    erros.push('Não foi definida a função de mudança');
  }
  if (!name) {
    name = 'namealeatorio';
    erros.push('Não há nome definido para o elemento');
  }
  const handleChange = (event) => {
    const evento = event;
    if (evento) evento.name = name;
    onChange(event ? evento : { name: name, value: null });
  };
  //if (!optionsList) return 'Erro, sem elementos para buscar';

  return (
    <Form.Group keyp={keyp} className="w-100" controlId={name}>
      <Form.Label>{label}</Form.Label>
      {isLoading ? ( // Exibe um spinner enquanto as opções estão carregando
        <div className="container-fluid justify-content-center bg-white align-items-center w-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        </div>
      ) : (
        <CreatableSelect
          autoComplete="off"
          keyp={keyp}
          name={name}
          value={
            {
              label: value,
              value: value,
            } /*optionsList.find((option) => option.value === value)*/
          }
          onChange={handleChange}
          options={optionsList}
          isDisabled={disabled}
          isClearable
          required
        />
      )}
      {hasError && (
        <div className="text-danger">Por favor, selecione um valor.</div>
      )}
      {erros.map((e) => (
        <Alert key="e" variant="danger">
          {e}
        </Alert>
      ))}
    </Form.Group>
  );
};
FixedOption.propTypes = {
  onChange: PropTypes.func.isRequired,
  optionsList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  keyp: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
export default FixedOption;
