import React from 'react';
import { Form, Spinner, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';

const FixedOption = ({
  label,
  name,
  optionsList,
  isLoading,
  onChange,
  value = '',
  required,
  placeholder = 'Selecione uma opção',
  keyp,
}) => {
  const erros = [];

  let disabled = false;
  //Se não há opções, mostrar erro

  const hasError = required && !value; // Check for validation error

  if (!optionsList || optionsList.length === 0) {
    erros.push(
      'Não há opção para selecionar. Verifique se esse campo já foi cadastrado em outro formulário',
    );
    disabled = true;
  }
  if (!name) {
    name = 'namealeatorio';
    erros.push('Não há nome definido para o elemento');
  }
  const mappedOptions = optionsList.map((arr) => ({
    value: arr[0],
    label: arr[1] || arr[0],
    name: name,
  }));

  if (!onChange) {
    onChange = () => ({});
    erros.push('Não foi definida a função de mudança');
  }

  const handleChange = (selectedOption) => {
    onChange(selectedOption || null);
  };
  //if (!optionsList) return 'Erro, sem elementos para buscar';

  return (
    <Form.Group keyp={keyp} className="w-100" controlId={name}>
      <Form.Label>{label}</Form.Label>
      {isLoading ? (
        <div className="container-fluid justify-content-center bg-white align-items-center w-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        </div>
      ) : (
        <Select
          autoComplete="off"
          name={name}
          value={mappedOptions.find((option) => option.value === value)}
          onChange={handleChange}
          options={mappedOptions}
          isDisabled={disabled}
          isClearable
          placeholder={placeholder} // Use o placeholder personalizado
          styles={{
            control: (provided) => ({
              ...provided,
              borderColor: hasError ? 'red' : provided.borderColor,
              '&:hover': {
                borderColor: hasError ? 'red' : provided.borderColor,
              },
            }),
          }}
        />
      )}
      {hasError && (
        <div className="text-danger">Por favor, selecione um valor.</div>
      )}
      {erros.map((e) => (
        <Alert key={e} variant="danger">
          {e}
        </Alert>
      ))}
    </Form.Group>
  );
};
FixedOption.propTypes = {
  onChange: PropTypes.func.isRequired,
  optionsList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  keyp: PropTypes.string,
};
export default FixedOption;
